<template>
  <div>
    <b-card title="¿Cuantas divisiones verticales? " class="text-center mb-0">
      <b-row class="justify-content-md-center mx-3 mb-1">
        <b-col col md="6" lg="3">
          <b-form-input
            id="vdivisions"
            v-model.number="vdivisions"
            class="text-center"
            type="number"
            name="vdivisions"
            @input="setDivisions"
          />
        </b-col>
        
        <b-col cols="auto">
          <h5 class="mt-1 text-left">(  {{anchura}}  X  {{altura}} )</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols=12 class="mt-2">
          <table id="esquema" class="table">
            <tr v-if="tapeta[0]">
              <td class="tapeta-top" :colspan="tapetaCol + vdivisions - 1"></td>
              <td  class="mesure"></td>
            </tr>            
            <tr :style="'height :'+ register_y +'px'" v-if="register">
              <td class="tapeta" v-if="tapeta[2]" rowspan="2"></td>
              <td :colspan="vdivisions" style="border : 1px solid black; background-color: #BBB;"></td>
              <td class="tapeta" v-if="tapeta[3]" rowspan="2"></td>              
              <td class="mesure"> <span>{{register}}</span></td>
            </tr>
            <tr :style="'height :'+ table_y +'px'">
              <td class="tapeta" v-if="!register && tapeta[2]"></td>
              <td v-for="index in vdivisions" :key="index" style="border : 1px solid black"></td>
              <td class="tapeta" v-if="!register && tapeta[3]"></td>              
              <td class="mesure"> <span>{{altura}}</span></td>
            </tr>
            <tr v-if="tapeta[1]">
              <td class="tapeta-top" :colspan="tapetaCol + vdivisions - 1"></td>
              <td  class="mesure"></td>
            </tr>               
            <tr style="height : 50px">
              <td style="padding: 0 !important;" v-if="tapeta[2]"></td>
              <td v-for="index in vdivisions" :key="index">
                <b-form-input
                  :id = "'vdiv-' + index"
                  v-model.number="v_array[index -1]"
                  type="number"
                  size = "sm"
                  class="text-center"
                  :name="'vdiv['+index+']'"
                />  
              </td>
              <td v-if="!register && tapeta[3]"></td>
              <td></td>              
            </tr>
          </table>
        </b-col>
        <b-col cols=12 class="mb-2"> Introduce las medidas de las divisiones</b-col>
      </b-row>
      <b-button type="button" variant="success" block @click="next"> Continuar </b-button>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  BCard,
  BCol,
  BRow,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BFormInput,
  },
  data() {
    return {
      vdivisions : 1,
      v_array : [],
      table_y : 100,
      register_y : 100
    };
  },
  created () {
    this.v_array[0] = this.anchura;
  },
  mounted () {
    let clientWidth = document.getElementById('esquema').clientWidth - 25 
    clientWidth = this.tapeta[2] ? (clientWidth -25) : clientWidth;
    clientWidth = this.tapeta[3] ? (clientWidth -25) : clientWidth;
    this.register_y = ((clientWidth * this.register) / this.anchura);
    // this.register_y = ((clientWidth * this.register) / this.anchura) - 56 - (21*this.tapeta[2]) - (21 * this.tapeta[3]);
    this.table_y = ((clientWidth * this.altura) / this.anchura);
  },
  methods : {
    setDivisions() {
      let x_width = (Math.floor(((this.anchura / this.vdivisions) + Number.EPSILON) * 100) /100) ;    
      this.v_array = [];
      for(let i = 0 ; i < this.vdivisions; i++){
        this.v_array.push(x_width);
      }
    }, 
    next() {
      if(this.anchura != this.anchuraIntroducida){
        this.$bvToast.toast('Las medidas introducidas (' + this.anchuraIntroducida + ') no coinciden con el marco.', {variant: 'danger', title : 'Error' } );
        return 0
      }
      let store_divisions = []
      for (let i = 0; i < this.v_array.length; i++) {
          store_divisions.push({width : this.v_array[i], rows : [{height: this.altura}]})
      }
      this.$store.commit('ventanas/setDivisions', store_divisions)
      this.$router.push('/hdivisions');
    }
  },
  computed: {
    ...mapState({
      register : state => state.ventanas.complements.registro_medida,
      tapeta : state => state.ventanas.complements.tapeta_values,
      anchura: state => state.ventanas.dimensions.width,
      altura: state => state.ventanas.dimensions.height,
    }),
    anchuraIntroducida: function () {
      let total = 0;
      for (let i = 0; i < this.v_array.length; i++) {
          total += this.v_array[i]
      }
      return total;
    },
    tapetaRow : function () {
      let rowspan = 1;
      if(this.tapeta[0]){
        rowspan++;
      }
      if (this.tapeta[1]) {
        rowspan++;
      }
      return rowspan;
    },
    tapetaCol: function () {
      let colspan = 1;
      if(this.tapeta[2]){
        colspan++;
      }
      if (this.tapeta[3]) {
        colspan++;
      }
      return colspan;
    }
  }
};
</script>

<style>
#esquema {
  width : 100%;
  max-width : 600px;
  margin : 0 auto
}
.mesure span {
  display: block;
  font-size: .857rem;
  transform: rotate(90deg);
}
.mesure {
  width: 25px !important;
  padding: 0 !important;
}
.tapeta-top {
  background-color: #333;
  border : 1px solid #333 !important;
  height: 25px;
  padding: 0 !important;  
}
.tapeta {
  background-color: #333;
  border : 1px solid #333 !important;
  height: 25px;
  width: 25px;
  padding: 0 !important;
}
</style>